import React from "react";
import Slider from "react-slick";

import caroOneImage from './images-custom-plugs/MPLUG_Bisley_OW.png';
import caroTwoImage from './images-custom-plugs/MPLUG_hafele_OW.png';
import caroThreeImage from './images-custom-plugs/MPLUG_haworth_OW.png';
import caroFourImage from './images-custom-plugs/MPLUG_HermanMiller_OW.png';
import caroFiveImage from './images-custom-plugs/MPLUG_JuNie_OW.png';
import caroSixImage from './images-custom-plugs/MPLUG_Ocura_OW.png';
import caroSevenImage from './images-custom-plugs/MPLUG_Steelcase_OW.png';
import caroEightImage from './images-custom-plugs/MPLUG_Timberline_OW.png';
import caroNineImage from './images-custom-plugs/MPLUG_Wesko1_OW.png';
import caroTenImage from './images-custom-plugs/MPLUG_Wesko2_OW.png';
class ReactSlickPlugs extends React.Component {
  render() {
    let settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      // variableWidth: true,
      cssEase: "ease"
    };
    return (
        <div className="slider-container">
          <Slider {...settings}>
            <div>
              <img src={caroOneImage} alt={'Bisley lock core'} />
            </div>
            <div>
              <img src={caroTwoImage} alt={'Hafele lock core'} />
            </div>
            <div>
              <img src={caroThreeImage} alt={'Haworth lock core'} />
            </div>
            <div>
              <img src={caroFourImage} alt={'HermanMiller lock core'} />
            </div>
            <div>
              <img src={caroFiveImage} alt={'Junie lock sore '} />
            </div>
            <div>
              <img src={caroSixImage} alt={'Ocura Healthcare Furniture lock core'}/>
            </div>
            <div>
              <img src={caroSevenImage} alt={'Steelcase lock core'} />
            </div>
            <div>
              <img src={caroEightImage} alt={'CompX Timberline lock core'} />
            </div>
            <div>
              <img src={caroNineImage} alt={'Wesko lock core'} />
            </div>
            <div>
              <img src={caroTenImage} alt={'Wesko lock core alternate image'} />
            </div>
          </Slider>
        </div>
    );
  }
}

export default ReactSlickPlugs;